import * as React from 'react';
import { useEffect, useRef } from 'react';
import BoxFull from '@/components/box/BoxFull';
interface ManifestTerminalProps {
  toTop: () => void;
}

const completeText = `> The Information Security Industry doesn't have a direct way to support Offensive & Defensive Open Source Security Tool developers even though it relies on them for a large portion of their services and/or internal capabilities.

We're here to change that. 

Porchetta Industries provides a centralized platform for organizations to fund and support Open Source Security Tools.`;

export default function ManifestTerminal({ toTop }: ManifestTerminalProps) {
  const spanCursor = useRef<HTMLSpanElement>(null);
  const divPre = useRef<HTMLPreElement>(null);
  const spanAnim = useRef<HTMLElement>(null);
  const manifestDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const manifest = manifestDiv.current;
    const pre = divPre.current;
    const anim = spanAnim.current;
    const cursorEl = spanCursor.current;
    if (!pre || !anim || !manifest || !cursorEl) {
      return;
    }

    let visible = false;
    let animating = false;
    let intro = false;
    let text = '';
    let cursor = false;
    let idx = 0;
    let frame = 0;
    const speed = 45;
    let wait = Math.floor(2000 / speed);
    let last: number | undefined = undefined;
    let animationInterval: any;
    let introSet = false;
    let textSet = false;
    function step(timestamp: number) {
      if (last === undefined) last = timestamp;
      const elapsedFromLast = timestamp - last;
      if (elapsedFromLast > speed) {
        last = timestamp;
        frame++;
        if (wait) {
          animating = true;
          intro = true;
          wait--;
        } else if (idx <= completeText.length) {
          intro = false;
          idx++;
          const newText = completeText.substring(0, idx + 1);
          if (text.length > newText.length) {
            idx = text.length;
          }
          text = newText;
        }
        if (frame % 15 === 0) {
          cursor = !cursor;
        }
        if (intro && pre) {
          pre.innerHTML = !cursor ? '** CLICK TO SKIP INTRO **' : '&nbsp;';
        } else if (!intro && pre && !introSet) {
          pre.textContent = '# Manifesto';
          introSet = true;
        }
        //if (cursorEl) cursorEl.innerHTML = cursor ? '|' : ' ';

        if (anim && animating && !textSet) {
          anim.textContent = text;
          if (text === completeText) {
            textSet = true;
            clearInterval(animationInterval);
            return;
          }
        }
      }
      animationInterval = window.requestAnimationFrame(step);
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.1) {
            if (!visible) {
              visible = true;
              animationInterval = window.requestAnimationFrame(step);
            }
          }
        });
      },
      {
        root: null,
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5],
      },
    );

    observer.observe(anim);
    const onClick = () => {
      animating = false;
      anim.innerHTML = completeText;
      wait = 0;
    };
    manifest.addEventListener('click', onClick);
    return () => {
      manifest.removeEventListener('click', onClick);
      observer.disconnect();
      cancelAnimationFrame(animationInterval);
    };
  }, [divPre, spanAnim, manifestDiv]);

  return (
    <div id="manifest" className="mx-auto" ref={manifestDiv}>
      <BoxFull type={'close'} onClose={toTop} title={'Porchetta Industries'}>
        <pre ref={divPre} id="manifestPre" className="flex justify-center mb-5" />
        <div className="whitespace-pre-line h-[48rem] sm:h-[23rem] break-words text-lg">
          <span ref={spanAnim} />
          <span ref={spanCursor} />
        </div>
      </BoxFull>
    </div>
  );
}
