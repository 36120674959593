import React from 'react';
import BoxTitle from '@/components/box/BoxTitle';

type BoxFullProps = {
  title: string;
  mainTitle?: string | null;
  children: React.ReactNode;
  type?: 'close' | 'happy';
  onClose?: () => void;
  className?: string;
};
const BoxFull: React.FC<BoxFullProps> = ({
  title,
  mainTitle,
  children,
  onClose,
  type = 'close',
  className = 'max-w-xl',
}: BoxFullProps) => (
  <div className={`mt-5 mb-20 mx-auto ${className}`}>
    <div className="bg-porchetta-dark border-2 text-porchetta-green border-porchetta-green shadow-porchetta">
      <BoxTitle type={type} title={title} onClose={onClose} />
      <div className="flex flex-col p-10">
        {mainTitle ? (
          <h2 className="text-5xl mb-4 uppercase font-titles-bold font-extrabold">{mainTitle}</h2>
        ) : null}
        <div className="">{children}</div>
      </div>
    </div>
  </div>
);

export default BoxFull;
