import React from 'react';

import Card from './Card';
import usePrices, { Price } from '@/components/hooks/usePrices';
import Loading from '@/components/spinner/Loading';

export type SubscriptionsProps = {
  subscription: Price | null;
  onChange?: (subscription: Price) => void;
  onSelect?: (subscription: Price) => void;
  className?: string;
};

const SubscriptionsList: React.FC<SubscriptionsProps> = ({
  subscription,
  onChange,
  onSelect,
  className = '',
}: SubscriptionsProps) => {
  const { prices, loading } = usePrices();
  const change = (nextSubscription: Price) => {
    onChange && onChange(nextSubscription);
  };
  const handleCardChange = (nextSubscription: Price) => () => {
    change(nextSubscription);
  };
  const handleCardSelect = (nextSubscription: Price) => () => {
    change(nextSubscription);
    onSelect && onSelect(nextSubscription);
  };
  if (loading || !prices) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className={className}>
      {prices.map((choice) => (
        <div key={choice.price_id} className="mt-10">
          <Card
            {...choice}
            selected={!!subscription && subscription.type === choice.type}
            onSelect={handleCardSelect(choice)}
            onChange={handleCardChange(choice)}
          />
        </div>
      ))}
    </div>
  );
};

export default SubscriptionsList;
