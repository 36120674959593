import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { classNames } from '@/lib/utils';
import { FieldProps } from 'formik';
import { ErrorField } from '@/components/forms/ErrorField';

type InputTextProps = { label?: string; theme?: 'green' | 'white' } & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const InputText = ({
  field,
  form,
  label,
  theme = 'white',
  className = '',
  ...props
}: Partial<FieldProps> & InputTextProps) => {
  const hasError = form?.errors ? !!form?.errors[field?.name || ''] : false;
  const isTouched = form?.touched ? !!form?.touched[field?.name || ''] : false;
  const showError = hasError && isTouched;

  return (
    <div className="flex flex-col flex-1">
      {label && <span className={`text-porchetta-${theme} block mb-2 mt-0 `}>{label}</span>}
      <input
        className={classNames({
          'flex-1 p-1': true,
          'border-porchetta-red text-porchetta-red': showError,
          [`border-porchetta-${theme} text-porchetta-${theme} focus:text-porchetta-green`]: !showError,
          'bg-porchetta-dark font-sm': true,
          'border outline-none': true,
          [className]: true,
        })}
        {...field}
        {...props}
      />

      {field && <ErrorField name={field.name} component="span" />}
    </div>
  );
};

export default InputText;
