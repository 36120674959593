import useSWR from 'swr';
import api from '@/lib/http/api';

export type SubscriptionTypes = 'yearly' | 'monthly';

export interface Price {
  stripe_id: string;
  backend_name: string;
  description: string;
  type: SubscriptionTypes;
  title: string;
  includes: string[];
  per_user: number;
  price_id: string;
}

export const MAX_USERS_ALLOWED = 9999;

export default function usePrices() {
  const { data, mutate, error } = useSWR('api_prices', api.getFetcher<Price[] | null>('/prices'), {
    shouldRetryOnError: true,
  });

  const loading = !data && !error;

  return {
    loading: loading,
    prices: data,
  };
}
