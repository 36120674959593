import { ToolInfo } from '@/lib/business/Tools';
import IconHeart from '@/components/icons/IconHeart';
import Github from '@/components/icons/Github';
import Twitter from '@/components/icons/Twitter';

type ToolWidgetItemProps = {
  tool: ToolInfo;
};
export default function ToolWidgetItem({ tool }: ToolWidgetItemProps) {
  return (
    <div className="relative border-0 border-porchetta-green">
      <div
        style={{
          position: 'absolute',
          top: '-0rem',
          left: '-3rem',
          borderTop: '1.5rem solid transparent',
          borderTopColor: 'transparent',
          borderRightColor: 'inherit',
          borderLeft: '1.5rem solid transparent',
          borderRight: '1.5rem solid',
        }}
      />
      <div className="p-4 h-[18rem] flex flex-col text-porchetta-white border-4 border-porchetta-green shadow-porchetta-bolder ">
        <div className="w-20 mx-auto">
          <IconHeart />
        </div>
        <div className="text-left flex-grow">
          <h4 className="mt-4 font-bold">{tool.name}</h4>
          <p className="text-xs ">{tool.desc}</p>
        </div>

        <ul className="text-porchetta-green text-xs">
          {tool.links.map((l) => (
            <li className="mt-1" key={l.href}>
              <a
                className="flex items-center"
                rel="noopener noreferrer"
                href={l.href}
                target="_blank">
                <span className="inline-flex font-bold w-3 mr-1">
                  {l.icon === 'github' ? <Github /> : <Twitter />}
                </span>
                {l.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '100%',
          right: '0',
          borderTop: '1.5rem solid',
          borderTopColor: 'inherit',
          borderLeft: '1.5rem solid transparent',
          borderRight: '1.5rem solid transparent',
        }}
      />
    </div>
  );
}
