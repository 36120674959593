import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FieldProps } from 'formik';
import { ErrorField } from '@/components/forms/ErrorField';
import { classNames } from '@/lib/utils';

type InputCheckboxProps = {
  dark?: boolean;
  themeSize?: 'xs' | 'base';
  label?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputCheckbox = ({
  checked = false,
  field,
  themeSize = 'base',
  form: _,
  label,
  ...props
}: Partial<FieldProps> & InputCheckboxProps) => {
  return (
    <label className="cursor-pointer inline-block relative select-none">
      <input
        type="checkbox"
        id={field && field.name ? field.name : ''}
        className="appearance-none absolute"
        {...field}
        {...props}
      />
      <label htmlFor={field && field.name ? field.name : ''} className="flex items-center">
        <span
          className={classNames({
            'text-porchetta-dark border border-2 align-center justify-center items-center flex leading-6 mr-1 ': true,
            'w-6 h-6 p-1': themeSize === 'base',
            'w-4 h-4 p-0': themeSize === 'xs',
            'bg-porchetta-green border-porchetta-green': checked,
            'bg-porchetta-dark border-porchetta-white': !checked,
          })}>
          <span
            className={classNames({
              invisible: !checked,
              'w-4': themeSize === 'xs',
              'w-5': themeSize === 'base',
            })}
            children={
              <svg viewBox="0 0 15 15" fill="#000" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.5 10L5.63981 11.2288L6.81795 12.0536L7.69154 10.9112L6.5 10ZM0.639807 7.72885L5.63981 11.2288L7.36019 8.77115L2.36019 5.27115L0.639807 7.72885ZM7.69154 10.9112L14.1915 2.41118L11.8085 0.588825L5.30846 9.08882L7.69154 10.9112Z"
                  fill="black"
                />
              </svg>
            }
          />
        </span>
        {label && (
          <span
            className={classNames({
              'text-porchetta-white block mt-0 ': true,
              'text-xs': themeSize === 'xs',
              'text-base': themeSize === 'base',
            })}>
            {label}
          </span>
        )}
      </label>

      {field && <ErrorField name={field.name} component="span" />}
    </label>
  );
};

export default InputCheckbox;
