export type ToolInfo = {
  name: string;
  desc: string;
  links: { icon: 'twitter' | 'github'; href: string; label: string }[];
};

export const TOOLS: ToolInfo[] = [
  {
    name: `CrackMapExec`,
    desc: `A Swiss Army knife for network pentesting`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/byt3bl33d3r`, label: `@byt3bl33d3r` },
      { icon: `github`, href: `https://github.com/byt3bl33d3r`, label: `@byt3bl33d3r` },
      { icon: `twitter`, href: `https://twitter.com/mpgn_x64`, label: `@mpgn_x64` },
      { icon: `github`, href: `https://github.com/mpgn`, label: `@mpgn` }
    ],
  },
  {
    name: `Pypykatz`,
    desc: `Platform-independednt mimikatz implementation in Python`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/Skelsec`, label: `@Skelsec` },
      { icon: `github`, href: `https://github.com/skelsec`, label: `@skelsec` },
    ],
  },
  {
    name: `aiosmb`,
    desc: `Platform-independent asynchronous SMB2/3 protocol implementation in Python`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/Skelsec`, label: `@Skelsec` },
      { icon: `github`, href: `https://github.com/skelsec`, label: `@skelsec` },
    ],
  },
 {
    name: `msldap`,
    desc: `Platform-independent asynchronous LDAP client implementation in Python`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/Skelsec`, label: `@Skelsec` },
      { icon: `github`, href: `https://github.com/skelsec`, label: `@skelsec` },
    ],
  },
 {
    name: `Kerberos`,
    desc: `Platform-independent asynchronous Kerberos client implementation in Python`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/Skelsec`, label: `@Skelsec` },
      { icon: `github`, href: `https://github.com/skelsec`, label: `@skelsec` },
    ],
  },
 {
    name: `Asysocks`,
    desc: `Platform-independent asynchronous SOCKS4/4a/5 and HTTP proxy client and server implementation in Python`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/Skelsec`, label: `@Skelsec` },
      { icon: `github`, href: `https://github.com/skelsec`, label: `@skelsec` },
    ],
  },
  {
    name: `DOUBLETAP`,
    desc: `An asynchronous HTTP proxy that uses AWS API Gateway IP rotation  on each request`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/byt3bl33d3r`, label: `@byt3bl33d3r` },
      { icon: `github`, href: `https://github.com/byt3bl33d3r`, label: `@byt3bl33d3r` },
    ],
  },
  {
    name: `pyMalleableC2`,
    desc: `Python Interpreter for Cobalt Strike Malleable C2 profiles`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/byt3bl33d3r`, label: `@byt3bl33d3r` },
      { icon: `github`, href: `https://github.com/byt3bl33d3r`, label: `@byt3bl33d3r` },
    ],
  },
  {
    name: `npk`,
    desc: `A mostly-serverless distributed hash cracking platform`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/bradwoodward_io`, label: `@bradwoodward_io` },
      { icon: `github`, href: `https://github.com/c6fc`, label: `@c6fc` },
    ],
  },
  {
    name: `warcannon`,
    desc: `High speed/Low cost CommonCrawl RegExp in Node.js`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/bradwoodward_io`, label: `@bradwoodward_io` },
      { icon: `github`, href: `https://github.com/c6fc`, label: `@c6fc` },
    ],
  },
  {
    name: `Chameleon`,
    desc: `PowerShell Script Obfuscator `,
    links: [
      { icon: `twitter`, href: `https://twitter.com/KlezVirus`, label: `@KlezVirus` },
      { icon: `github`, href: `https://github.com/klezVirus`, label: `@klezVirus` },
    ],
  },
  {
    name: `Vortex`,
    desc: `VPN Overall Reconnaissance, Testing, Enumeration and eXploitation Toolkit`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/KlezVirus`, label: `@KlezVirus` },
      { icon: `github`, href: `https://github.com/klezVirus`, label: `@klezVirus` },
    ],
  },
  {
    name: `SysWhisper3`,
    desc: `SysWhispers on Steroids - AV/EDR evasion via direct system calls. `,
    links: [
      { icon: `twitter`, href: `https://twitter.com/KlezVirus`, label: `@KlezVirus` },
      { icon: `github`, href: `https://github.com/klezVirus`, label: `@klezVirus` },
    ],
  },
  {
    name: `Inceptor`,
    desc: `Template-Driven AV/EDR Evasion Framework`,
    links: [
      { icon: `twitter`, href: `https://twitter.com/KlezVirus`, label: `@KlezVirus` },
      { icon: `github`, href: `https://github.com/klezVirus`, label: `@klezVirus` },
    ],
  }
];
