import * as React from 'react';
import Head from 'next/head';
import Main, { makeTitle } from '@/components/layouts/Main';
import HeroTitle from '@/components/widgets/HeroTitle';
import Box from '@/components/box/Box';
import BgPattern from '@/components/pattern/BgPattern';
import InputText from '@/components/inputs/Text';
import SubscriptionsList from '@/components/Subscriptions/SubscriptionsList';
import ROUTES from '@/lib/routes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const Home = () => {
  const router = useRouter();
  const [subscription, setSubscription] = useState<Price | null>(null);
  const [scrollInTop, setScrollInTop] = useState(true);
  const [goToTopElOverlapsFooter, setGoToTopElOverlapsFooter] = useState(false);
  useEffect(() => {
    const goToTopEl: HTMLElement = document.querySelector('#goToTopEl') as HTMLElement;
    const footerEl: HTMLElement = document.querySelector('#footer-green') as HTMLElement;
    const listener = debounce(() => {
      if (window.document.scrollingElement && window.document.scrollingElement.scrollTop === 0) {
        setScrollInTop(true);
      } else {
        setScrollInTop(false);
      }
      if (!goToTopEl || !footerEl) {
        return;
      }
      const rect1 = goToTopEl.getBoundingClientRect();
      const rect2 = footerEl.getBoundingClientRect();
      setGoToTopElOverlapsFooter(
        !(
          rect1.right < rect2.left ||
          rect1.left > rect2.right ||
          rect1.bottom < rect2.top ||
          rect1.top > rect2.bottom
        ),
      );
    }, 200);
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => router.push({ pathname: ROUTES.home }, undefined, { shallow: true }), 1000);
  };
  return (
    <>
      <Head>
        <title>{makeTitle('Porchetta')}</title>
      </Head>
      <div className="mt-5">
        <HeroTitle>
          <div className="text-white font-bold">
            <p>Does your organization rely on Open Source Security Tools?</p>
            <p>
              Directly support the developers! Get access to exclusive updates, perks and content.
            </p>
          </div>
        </HeroTitle>

        <BgPattern align={'center left'} />

        <ManifestTerminal toTop={toTop} />

        <BgPattern align={'center right'} />

        <button
          id="goToTopEl"
          className={classNames({
            'fixed z-30 right-8 bottom-10 focus:outline-none w-14 h-14': true,
            hidden: scrollInTop,
            'text-porchetta-green': !goToTopElOverlapsFooter,
            'text-porchetta-dark': goToTopElOverlapsFooter,
          })}
          onClick={toTop}>
          <IconGoToTop />
        </button>

        <div className="max-w-xl" id="tools">
          <h2 className="text-4xl uppercase font-titles-bold font-extrabold">Tools </h2>
          <p className="mt-4 text-porchetta-white">
            The following is a list of tools we currently have on our platform and are being funded
            by Porchetta Industries. As we continue to grow, we will invite new tool developers to
            join us. The more subscribers we get the more tools we can fund.
          </p>
        </div>

        <ToolWidget />

        <BgPattern align={'center left'} />

        <div className="max-w-md" id="subscriptions">
          <h2 className="text-4xl uppercase font-titles-bold font-extrabold">Subscriptions</h2>
          <p className="mt-4 max-w-xs text-porchetta-white">
            Porchetta Industries uses Stripe to process subscriptions, once a package has been selected you will be securely redirected to Stripe. 
          </p>
        </div>
        <div className="mx-auto max-w-xl mt-10">
          <SubscriptionsList
            subscription={subscription}
            onChange={(selected) => {
              setSubscription(selected);
            }}
            onSelect={(selected) => {
              router.push(`${ROUTES.subscriptions}?selected=${selected.type}`);
            }}
          />
        </div>

        <BgPattern align={'bottom right'} />

        <div
          id="newsletter"
          className="mt-5 mb-40 align-center mx-auto flex justify-center max-w-md">
          <Box title={'Subscribe to our newsletter'} onClose={toTop}>
            <form action="https://industries.us1.list-manage.com/subscribe/post" method="POST">
              <div className="mx-3 sm:mx-10 mt-14 mb-20">
                <div className="sm:flex items-center">
                  <InputText
                    name="MERGE0"
                    id="MERGE0"
                    type="email"
                    theme={'green'}
                    placeholder={'Email'}
                  />
                  <div className="mt-5 sm:mt-0 sm:ml-2 flex justify-end">
                    <Button outline type="submit" size={'small'} active>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
              <input type="hidden" name="u" value="6392e5af8c02ece0b91d11a60" />
              <input type="hidden" name="id" value="7a417a8ee9" />
              <input type="hidden" name="mc_signupsource" value="porchetta-website" />
            </form>
          </Box>
        </div>
      </div>
    </>
  );
};

Home.Layout = Main;

import { GetStaticProps } from 'next';
import { classNames } from '@/lib/utils';
import { ToolWidget } from '@/components/tools/ToolWidget';
import IconGoToTop from '@/components/icons/IconGoToTop';
import { Price } from '@/components/hooks/usePrices';
import ManifestTerminal from '@/components/widgets/ManifestTerminal';
import Button from '@/components/inputs/Button';
import { debounce } from '@/lib/debounce';

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      isHome: true,
    },
  };
};

export default Home;
