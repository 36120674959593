import React from 'react';
import styled from '@emotion/styled';

import Checkbox from '../inputs/Checkbox';
import Button from '../inputs/Button';

type CardProps = {
  title: string;
  description: string;
  includes: Array<string>;
  selected: boolean;
  onSelect?: () => void;
  onChange?: () => void;
};

export const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.dark};
  border: 0.125rem solid ${({ theme }) => theme.colors.green};
  box-shadow: -0.5rem 0.5rem 0px 0px ${({ theme }) => theme.colors.green};
  cursor: pointer;
  padding: 1rem;
  user-select: none;
`;

const Title = styled.h3`
  align-items: center;
  color: ${({ theme }) => theme.colors.green};
  display: flex;
  font-family: 'Source Code Pro';
  font-size: 1.125rem;
  margin: 0;
  padding: 0;

  & > div {
    flex: 1;
    text-align: right;
  }
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  margin-bottom: 1.25rem;
  margin-top: 0.25rem;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    &::before {
      content: '/';
      margin: 0 0.5rem;
    }
  }

  & > li:first-of-type,
  & > li:last-of-type {
    &::before {
      content: none;
    }
  }

  & > li:last-of-type {
    flex: 1;
    text-align: right;
  }
`;

const Card: React.FC<CardProps> = ({
  title,
  description,
  includes,
  selected,
  onSelect,
  onChange,
}: CardProps) => {
  const handleClickChange = () => {
    onChange && onChange();
  };
  const handleClickSelect = () => {
    onSelect && onSelect();
  };

  return (
    <StyledCard onClick={handleClickChange}>
      <Title>
        {title}
        <div>
          <Checkbox checked={selected} onChange={handleClickChange} />
        </div>
      </Title>
      <Description>{description}</Description>
      <List>
        {includes.map((item) => (
          <li key={item}>{item}</li>
        ))}
        <li>
          <Button dark outline size="large" onClick={handleClickSelect}>
            Next
          </Button>
        </li>
      </List>
    </StyledCard>
  );
};

export default Card;
