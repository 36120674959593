export default function IconGoToTop() {
  return (
    <svg viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6057 13.3335L38.061 13.3335L38.061 21.8666L43.8781 21.8666L43.8781 30.3335L49.6058 30.3335L49.6058 38.8666L55.3334 38.8666L55.3334 47.3335L9.33337 47.3335L9.33337 38.8666L15.061 38.8666L15.061 30.3335L20.7886 30.3335L20.7886 21.8666L26.6057 21.8666L26.6057 13.3335Z"
        fill="currentColor"
      />
    </svg>
  );
}
