type Props = {
  align: string;
};

export default function BgPattern({ align }: Props) {
  return (
    <div className="flex relative z-10">
      <div
        className="my-0 relative h-[30rem] w-screen inset-x-1/2	ml-[-50vw] mr-[-50vw] "
        style={{
          background: `url(/images/pattern-bg.svg) ${align}`,
          //animation: 'animatedBackground 2s linear infinite',
        }}
      />
      <div
        className="relative text-porchetta-green my-0 relative h-[30rem] w-screen inset-x-1/2 -m-	ml-[-50vw] mr-[-50vw] "
        style={{
          background: `url(/images/pattern-bg.svg) ${align}`,
        }}
      />
    </div>
  );
}
