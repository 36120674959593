import { TOOLS } from '@/lib/business/Tools';
import ToolWidgetItem from '@/components/tools/ToolWidgetItem';

export function ToolWidget() {
  return (
    <div className="ml-6 lg:ml-0 -mt-10 flex flex-wrap justify-center">
      {TOOLS.map((tool) => (
        <div key={tool.name} className="max-w-[12rem] mt-20 mr-16">
          <ToolWidgetItem key={tool.name} tool={tool} />
        </div>
      ))}
    </div>
  );
}
