import * as React from 'react';
import { PropsWithChildren } from 'react';

export default function HeroTitle({
  children,
  title = `Porchetta Industries`,
}: PropsWithChildren<{ title?: string }>) {
  return (
    <div className="relative z-20 inset-x-0 top-0 min-h-[25rem] sm:min-h-[45rem] md:min-h-[45rem] select-none">
      <div className="absolute top-0 z-10 max-w-xl">
        <h1 className="text-3xl sm:text-7xl uppercase font-titles-bold font-extrabold ">{title}</h1>
        {children}
      </div>
    </div>
  );
}
